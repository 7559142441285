<template>
  <div id="Test">
    <nav>
      <p>
        <span style="cursor:pointer" @click="ToCourse">我的课程</span>
        / 课程考试
      </p>
    </nav>
    <main>
      <ul>
        <li class="tittle">
          <h3>{{ testInfo.name }}</h3>
        </li>
        <li class="note">
          考试时间 |
          <span>{{ testInfo.testTime }}</span>
          分钟
        </li>
      </ul>
    </main>
    <footer>
      <div class="left">
        <div class="list-style-v1">
          <el-row :gutter="20">
            <el-col :sm="18" :xs="24">
              <div class="question-list mb-20">
                <div class="title">
                  一、单选题（本类题共{{ testInfo.count }}小题，每题1分，共{{
                    testInfo.count
                  }}分。错选、不选均不得分。）
                </div>

                <div class="item" v-for="(item, index) in testInfo.list" :key="item.id">
                  <!--问题-->
                  <div class="question-name" :id="item.id">{{ index + 1 }}.{{ item.content }}</div>

                  <!--选择答案-->
                  <ul class="answer-list">
                    <el-radio-group v-model="item.value">
                      <li v-for="items in item.list" :key="items.id" class="answer-item">
                        <el-radio :label="items.id">{{ items.content }}</el-radio>
                      </li>
                    </el-radio-group>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="right">
        <header>
          答题统计
        </header>
        <ul>
          <li
            v-for="(item, index) in testInfo.list"
            :key="item.id"
            :class="item.value ? ' replyActive' : ''"
            @click="changeHash(item.id)"
          >
            {{ index + 1 }}
          </li>
        </ul>
        <hr />
        <div style="margin: 10px;">
          <p>
            已答
            <span style="color:#f7b515">{{ doCount }}</span>
            道题，共
            <span>{{ testInfo.count }}</span>
            道题
          </p>
        </div>
        <div class="time">
          剩余时间：
          <span>{{ time }}</span>
        </div>
        <div style="text-align:center;">
          <el-button type="warning" @click="getSubmit">提交答案</el-button>
        </div>
        <el-dialog
          title="考试结果"
          :modal-append-to-body="false"
          :append-to-body="false"
          :close-on-click-modal="false"
          :visible.sync="dialogVisible"
          :show-close="false"
          width="30%"
        >
          <span>
            最终得分：
            <span style="color:#f7b515">{{ grade }}分</span>
          </span>
          <span>
            考试结果：
            <span style="color: #3de637;" v-show="result == 1 ? true : false">通过</span>
            <span style="color: red;" v-show="result == 0 ? true : false">未通过</span>
          </span>
          <br />
          <p v-show="result == 0 ? true : false">
            考试未通过，需要重新学习课程，点击
            <span class="ToGO" @click="Tostudy">此处</span>
            开始学习
          </p>
          <span slot="footer" class="dialog-footer">
            <el-button @click="ToCourse">关闭</el-button>
          </span>
        </el-dialog>
      </div>
    </footer>
  </div>
</template>

<script>
import API from '@/api';

export default {
  name: 'TestName',
  data() {
    return {
      ruleForm: {
        resource: '',
      },
      param: {
        id: '',
      },
      submit: {
        courseUserId: '',
        list: [],
        testRuleId: '',
        duration: '',
      },
      getTime: '',
      elapsedTime: '',
      textRuleId: '',
      testTime: '',
      totalTime: '',
      dialogVisible: false, // 弹窗
      grade: '', // 分数
      result: '',
      seconds: 0,
      time: '',
      reciprocal: Number,
      testInfo: '',
    };
  },
  computed: {
    doCount() {
      let count = 0;
      if (this.testInfo) {
        for (const item of this.testInfo.list) {
          if (item.value) {
            count += 1;
          }
        }
      }
      return count;
    },
  },
  created() {
    window.addEventListener('beforeunload', () => {
      // 刷新保留已选择答案
      sessionStorage.setItem('answer', JSON.stringify(this.testInfo));
      sessionStorage.setItem('testRuleId', this.submit.testRuleId);
      sessionStorage.setItem('CourseId', this.$route.query.courseId);
    });
    const CourseId = sessionStorage.getItem('CourseId');
    if (this.$route.query.courseId === CourseId) {
      if (sessionStorage.getItem('answer')) {
        // 刚进入考试的时间
        this.getTime = sessionStorage.getItem('getTime');
        this.submit.time = sessionStorage.getItem('submitTime');
        // 刷新保留已选择答案
        const answer = JSON.parse(sessionStorage.getItem('answer'));
        this.testInfo = answer;
        this.getSurplusTime();
        this.submit.testRuleId = sessionStorage.getItem('testRuleId');
        const reciprocal = sessionStorage.getItem('reciprocal');
        const seconds = sessionStorage.getItem('seconds');
        this.refresh(reciprocal, seconds);
      } else {
        this.getTest();
      }
    } else {
      this.getTest();
    }
    // this.getTest()
  },
  mounted() {},
  beforeDestroy() {
    sessionStorage.setItem('answer', '');
    sessionStorage.setItem('seconds', '');
    clearInterval(this.timer);
  },
  methods: {
    // 跳转到课程页
    ToCourse() {
      this.$router.push('/Course/MyCourse/My-Course');
    },
    // 跳转去学习
    Tostudy() {
      this.$router.push({
        path: '/Player',
        query: {
          courseId: this.$route.query.courseId,
          courseUserId: this.$route.query.courseUserId,
        },
      });
    },
    // 获取考试内容
    getTest() {
      const data = new Date();
      const year = data.getFullYear(); // 获取年
      const month = (data.getMonth() + 1).toString().padStart(2, '0'); // 获取月份
      const day = data
        .getDate()
        .toString()
        .padStart(2, '0'); // 获取日期
      const house = data
        .getHours()
        .toString()
        .padStart(2, '0'); // 获取小时
      const minutes = data
        .getMinutes()
        .toString()
        .padStart(2, '0'); // 获取分钟
      const seconds = data
        .getSeconds()
        .toString()
        .padStart(2, '0'); // 获取秒
      this.getTime = Date.parse(new Date());
      sessionStorage.setItem('getTime', this.getTime);
      this.submit.time = `${year}-${month}-${day} ${house}:${minutes}:${seconds}`;
      sessionStorage.setItem('submitTime', this.submit.time);
      this.param.id = this.$route.query.courseId;
      API.Test(this.param)
        .then((res) => {
          this.testInfo = res.message.data;
          this.testInfo.list = res.message.data.list;
          this.submit.testRuleId = res.message.data.testRuleId;
          this.getSurplusTime();
        })
        .catch((mgs) => {
          console.log(mgs);
        });
    },
    // 提前提交答案
    getSubmit() {
      this.submit.courseUserId = this.$route.query.courseUserId;
      let cheackValue = true;
      for (const item of this.testInfo.list) {
        if (!item.value || item.value === '') {
          this.$message({
            message: '请答完全部试题',
            type: 'warning',
          });
          cheackValue = false;
          break;
        }
      }
      this.testInfo.list.map((e) => (this.submit.list.push({ questionId: e.id, answerId: e.value })));
      if (cheackValue) {
        this.elapsedTime = Date.parse(new Date());
        const mistiming = this.elapsedTime / 1000 - this.getTime / 1000;
        console.log(mistiming);
        this.submit.duration = `${mistiming}秒`;
        clearInterval(this.tiemer);
        this.submitAnswer();
      }
    },
    // 时间到，自动提交答案
    submitAnswer2() {
      this.submit.testRuleId = this.testInfo.testRuleId;
      this.submit.courseUserId = this.$route.query.courseUserId;
      for (const item of this.testInfo.list) {
        this.submit.list.push({
          questionId: item.id,
          answerId: item.value,
        });
      }
      clearInterval(this.timer);
      API.getAnswer(this.submit)
        .then((res) => {
          if (res.message.success === true) {
            this.grade = res.message.data.count;
            this.result = res.message.data.result;
            this.dialogVisible = true;
          }
        })
        .catch((mgs) => {
          console.log(mgs);
        });
    },
    // 提交答案
    submitAnswer() {
      this.submit.testRuleId = this.testInfo.testRuleId;
      API.getAnswer(this.submit)
        .then((res) => {
          if (res.message.success === true) {
            this.grade = res.message.data.count;
            this.result = res.message.data.result;
            this.dialogVisible = true;
            clearInterval(this.timer);
          }
        })
        .catch((mgs) => {
          console.log(mgs);
        });
    },
    // 提交答案弹窗
    open() {
      this.$alert('这是一段内容', '标题名称', {
        confirmButtonText: '确定',
        callback: (action) => {
          this.$message({
            type: 'info',
            message: `action: ${action}`,
          });
        },
      });
    },
    // 剩余考试时间
    getSurplusTime() {
      this.reciprocal = this.testInfo.testTime;
      this.tiemer = setInterval(() => {
        this.seconds -= 1; // 60
        sessionStorage.setItem('seconds', this.seconds);
        if (this.seconds < 0) {
          this.reciprocal -= 1;
          sessionStorage.setItem('reciprocal', this.reciprocal);
          this.seconds = 59;
        }
        if (this.reciprocal <= 0 && this.seconds <= 0) {
          clearInterval(this.tiemer);
          this.submitAnswer2();
        }
        // this.time = `${this.reciprocal}` + '分' + `${this.seconds}` + '秒';
        this.time = `${this.reciprocal}分${this.seconds}秒`;
      }, 1000);
    },
    refresh(reciprocal, seconds) {
      this.reciprocal = reciprocal;
      this.seconds = seconds;
      this.tiemer = setInterval(() => {
        this.seconds -= 1; // 60
        sessionStorage.setItem('seconds', this.seconds);
        if (this.seconds <= 0) {
          this.reciprocal -= 1;
          sessionStorage.setItem('reciprocal', this.reciprocal);
          this.seconds = 59;
        }
        if (this.reciprocal <= 0 && this.seconds <= 0) {
          clearInterval(this.tiemer);
          this.submitAnswer2();
        }
        // this.time = `${this.reciprocal}` + '分' + `${this.seconds}` + '秒';
        this.time = `${this.reciprocal}分${this.seconds}秒`;
      }, 1000);
    },
    // 锚点
    changeHash(idName) {
      document.getElementById(idName).scrollIntoView(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.replyActive {
  background: #f7b515;
  border: 1px solid transparent;
}
main {
  width: 100%;
  height: 80px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin-top: 30px;
  .tittle {
    float: left;
    margin-left: 30px;
    line-height: 80px;
    height: 80px;
  }
  .note {
    float: right;
    margin-right: 30px;
    line-height: 80px;
    height: 80px;
  }
}
footer {
  //display: inline-block;
  display: flex;
  align-content: flex-start;
  width: 100%;
  //justify-content: space-between;
  .left {
    width: 100%;
    height: 700px;
    border: 1px solid #dddddd;
    padding: 10px;
    margin-top: 30px;
    overflow-x: hidden;
    border-radius: 5px;
    .list-style-v1 {
      &.item-no-margin {
        dl {
          margin: 0;
        }
      }
      dl {
        width: 100%;
        display: flex;
        margin: 0 0 20px;

        dt {
          flex: 0 0 auto;
          font-weight: bold;
        }

        dd {
          flex: 1 1 auto;
          margin-left: 0;
          white-space: normal;
          word-break: break-all;
        }
      }
    }
  }
  .right {
    width: 16vw;
    height: 400px;
    border: 1px solid #dddddd;
    margin-top: 30px;
    margin-left: 30px;
    overflow-x: hidden;
    border-radius: 3px 3px 0 0;
    header {
      // width: 16vw;
      height: 45px;
      line-height: 45px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      background: #f7b515;
      border-radius: 3px 3px 0 0;
    }
    ul {
      height: 200px;
      overflow-x: hidden;
    }
    ul li {
      width: 29px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border: 1px solid;
      margin: 14px;
      float: left;
      cursor: pointer;
    }
  }
}
.time {
  color: #f7b515;
  margin: 10px;
  font-size: 14px;
}
.submit {
  width: 300px;
  margin: 10px;
  text-align: center;
  button {
    width: 150px;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    background: #f7b515;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: 1px solid #f7b515;
    border-radius: 4px;
    margin-top: 10px;
  }
}
.ToGO {
  color: #f7b515;
  cursor: pointer;
}
</style>
